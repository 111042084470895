import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { MerchOperationService } from 'src/app/_services/merch-operation.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-merch-branch-name',
  templateUrl: './merch-branch-name.component.html',
  styleUrls: ['./merch-branch-name.component.scss']
})

export class MerchBranchNameComponent implements OnInit, AfterViewInit {

  Branchs: Array<any> = [];
  branchForm: FormGroup;
  accessToken: string;

  updating: boolean = false;
  branchIdToUpdate: Number = null;
  storeDropdownSettings: IDropdownSettings;

  @ViewChild(DataTableDirective, {
    static: false,
  })
  public dtElement: DataTableDirective;
  public dtOptions: DataTables.Settings = {};
  public dtTrigger: Subject<any> = new Subject();
  public store_type: Array<any> = [];
  public city: Array<any> = [];


  constructor(
    private fb: FormBuilder,
    private operationService: MerchOperationService
  ) { }

  ngOnInit(): void {

    this.accessToken = localStorage.getItem('accessToken');

    this.branchForm = this.fb.group({
      name: ['', [Validators.required]],
      name_ar: [''],
      store_type: ['', [Validators.required]],
      storeshow: ['', [Validators.required]],
      city: ['', [Validators.required]],
      cityshow: ['', [Validators.required]]

    });

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      dom: 'ltipr',
      stateSave: true,
      destroy: true
    }

    this.storeDropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };
    this.listAllCites()
    this.listAllBranchs();
  }

  ngAfterViewInit(): void {
    if (!this.dtElement.dtInstance) {
      this.dtTrigger.next();
    }
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  listAllStoreTypes(city_ids: number[]) {
    Swal.showLoading()
    this.operationService.getBasicStoreType({ city_ids }).subscribe(response => {

      Swal.close()
      if (response.success) {
        this.store_type = response?.data
        console.log(this.store_type)

      } else {
        Swal.fire({
          icon: 'error',
          title: response.error.error_message || "Problem in fetching store!"
        });
      }
    }, error => {
      Swal.close()
      Swal.fire({
        icon: 'error',
        title: error.error.error_message || "Problem in fetching stores!"
      });
    })
  }
  listAllCites() {
    Swal.showLoading()
    this.operationService.getCities().subscribe(response => {

      Swal.close()
      if (response.success) {
        this.city = response.data?.results
        console.log(this.city)

      } else {
        Swal.fire({
          icon: 'error',
          title: response.error.error_message || "Problem in fetching store!"
        });
      }
    }, error => {
      Swal.close()
      Swal.fire({
        icon: 'error',
        title: error.error.error_message || "Problem in fetching stores!"
      });
    })
  }

  listAllBranchs() {
    Swal.showLoading();
    this.operationService.getbranchs()
      .subscribe(response => {
        Swal.close();
        if (response.success) {
          this.Branchs = response.data?.results;

          console.log(this.Branchs)
          this.rerender();
        } else {
          Swal.fire({
            icon: 'error',
            title: response.error.error_message || "Problem in fetching Branchs!"
          });
        }
      }, error => {
        Swal.close()
        Swal.fire({
          icon: 'error',
          title: error.error.error_message || "Problem in fetching Branchs!"
        });
      })
  }

  removebranch(branchId) {
    this.operationService.removebranch(branchId, this.accessToken)
      .then(res => {
        Swal.fire({
          icon: "success",
          titleText: "branch deleted successfully!"
        }).then(res => {
          this.listAllBranchs();
        })
      })
      .catch(error => {
        Swal.fire({
          icon: "error",
          titleText: "Something went wrong while deleting branch!"
        })
      })
  }

  updatebranch() {
    if (this.branchForm.invalid) {
      for (var i in this.branchForm.controls) {
        this.branchForm.controls[i].markAsDirty();
        this.branchForm.controls[i].updateValueAndValidity();
      }
    } else {
      Swal.showLoading();
      if (this.branchIdToUpdate) {
        console.log(this.branchForm.value)
        this.operationService.updatebranch(this.branchIdToUpdate, this.branchForm.value)
            .subscribe(
              response => {
                Swal.close();
                if (response.success) {
                  Swal.fire({
                    icon: 'success',
                    title: "branch updated successfully!"
                  }).then(() => {
                    this.branchIdToUpdate = null;
                    this.listAllBranchs();
                    this.updating = false;
                    this.branchForm.reset();
                  })
                } else {
                  Swal.fire({
                    icon: 'error',
                    title: response.error_message || "Problem in updating branch!"
                  });
                }
              },
              error => {
                Swal.close();
                Swal.fire({
                  icon: 'error',
                  title: error.error.error_message || "Problem in adding branch!"
                });
              }
            )
      }
    }
  }

  addBranch() {
    this.branchForm.controls['store_type'].setValue(this.branchForm.controls['storeshow'].value[0].id)
    this.branchForm.controls['city'].setValue(this.branchForm.controls['cityshow'].value[0].id)

    if (this.branchForm.invalid) {
      console.log('Invalid form')
      for (var i in this.branchForm.controls) {
        this.branchForm.controls[i].markAsDirty();
        this.branchForm.controls[i].updateValueAndValidity();
      }
    } else {
      Swal.showLoading();

      this.operationService.addbranch(this.branchForm.value)
        .subscribe(
          response => {
            Swal.close();
            if (response.success) {
              Swal.fire({
                icon: 'success',
                title: "branch added successfully!"
              }).then(() => {
                this.listAllBranchs();
                this.updating = false;
                this.branchForm.reset();
              })
            } else {
              Swal.fire({
                icon: 'error',
                title: response.error_message || "Problem in adding branch!"
              });
            }
          },
          error => {
            Swal.close();
            Swal.fire({
              icon: 'error',
              title: error.error.error_message || "Problem in adding branch!"
            });
          }
        )
    }
  }

  searchText(searchText: any) {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.search(searchText.data).draw();
    });
  }

  onCitySelect(city) {
    this.listAllStoreTypes([city.id])
  }

  // helper methods
  fetchbranch(branch) {
    console.log(branch)
    this.updating = true;
    this.branchIdToUpdate = branch.id || null;
    this.branchForm = this.fb.group({
      name: [branch.name, [Validators.required]],
      name_ar: [branch.name_ar],
      store_type: [branch.store_type.id, [Validators.required]],
      storeshow: [[{
        'id': branch.store_type.id,
        'name': branch.store_type.name,
      }], [Validators.required]],
      city: [branch.city.id, [Validators.required]],
      cityshow: [[{
        'id': branch.city.id,
        'name': branch.city.name,
      }], [Validators.required]],

    });
  }

  toggleUpdateMode() {
    this.updating = false;
    this.branchForm.reset();
  }

  handleNotification(message: string, isError: boolean) {
    if (!isError) {
      Swal.fire({
        icon: 'success',
        title: message
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: message
      });
    }
  }

}
