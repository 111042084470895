<!DOCTYPE html>
<html lang="en">

<body>
    <div id="wrapper">
        <!-- Topbar Start -->
        <app-top-bar [pageTitle]="'Merchandizing User audits'" (searchText)="searchText($event)"></app-top-bar>
        <!-- end Topbar -->
    </div>
    <!-- Begin page -->
    <div id="wrapper" style="background-color: '#ebeff2';">

        <!-- ========== Left Sidebar Start ========== -->
        <app-left-side-bar></app-left-side-bar>
        <!-- Left Sidebar End -->

        <!-- ============================================================== -->
        <!-- Start Page Content here -->
        <!-- ============================================================== -->

        <div class="content-page">
            <div class="content">

                <!-- Start Content-->
                <div class="container-fluid">

                    <div class="mb-0">
                        <app-top-title [title]="'User audits history'"
                            [subTitle]="'You can View Users Audit\'s history data here.'"></app-top-title>
                    </div>
                    <div class="py-2"
                        style="background-color: 'white';margin-bottom: '10px';border-bottom: 10px solid #ebeff2;width: 100% !important;">

                        <form action="">
                            <div class="row p-3">

                                <div class=" col-md-2" style="width: 100%">
                                    <!-- Date range picker -->
                                    <label class=" col-form-label">Date Range: <span style="color:#ff0000">*</span>
                                    </label>
                                    <input id="daterangepicker" class="form-control" startKey="date_after"
                                        endKey="date_before" opens="right" drops="down"
                                        emptyWeekColumnClass="empty-week-column" ngxDaterangepickerMd
                                        [formControl]="dashboardForm.controls['date_range']"
                                        [locale]="{'format': 'MM/DD/YYYY'}" (change)="applyDateFilter()"
                                        [showCustomRangeLabel]="true" [alwaysShowCalendars]="true" [ranges]="ranges"
                                        [linkedCalendars]="true" [showDropdowns]="true"
                                        [showRangeLabelOnInput]="showRangeLabelOnInput"
                                        placeholder="Select the date range..." />
                                </div>
                                <!-- <div class=" col-md-2" style="width: 100%">
                                    <div class="form-group">
                                        <ng-multiselect-dropdown style="max-width: fit-content;" name="region"
                                            id="selRegion" [placeholder]="'Select Agent'"
                                            [settings]="dropdownMultiselectSettings" [data]="cities">
                                        </ng-multiselect-dropdown>
                                    </div>
                                </div>
                                <div class=" col-md-2" style="width: 100%">
                                    <div class="form-group">
                                        <ng-multiselect-dropdown style="max-width: fit-content;" name="region"
                                            id="selRegion" [placeholder]="'Select City Name'"
                                            [settings]="dropdownMultiselectSettings" [data]="cities">
                                        </ng-multiselect-dropdown>
                                    </div>
                                </div>
                                <div class=" col-md-2" style="width: 100%">
                                    <div class="form-group">
                                        <ng-multiselect-dropdown style="max-width: fit-content;" name="region"
                                            id="selRegion" [placeholder]="'Select Store Name'"
                                            [settings]="dropdownMultiselectSettings" [data]="cities">
                                        </ng-multiselect-dropdown>
                                    </div>
                                </div>
                                <div class=" col-md-2" style="width: 100%">
                                    <div class="form-group">
                                        <ng-multiselect-dropdown style="max-width: fit-content;" name="region"
                                            id="selRegion" [placeholder]="'Select Branch Name'"
                                            [settings]="dropdownMultiselectSettings" [data]="cities">
                                        </ng-multiselect-dropdown>
                                    </div>
                                </div>
                                <div class=" col-md-2" style="width: 100%">
                                    <div class="form-group">
                                        <ng-multiselect-dropdown style="max-width: fit-content;" name="region"
                                            id="selRegion" [placeholder]="'Select Store Type'"
                                            [settings]="dropdownMultiselectSettings" [data]="cities">
                                        </ng-multiselect-dropdown>
                                    </div>
                                </div> -->


                            </div>
                        </form>
                    </div>
                    <div class="mt-3 p-3 bg-white mb-5">
                        <div class="mt-2 mb-3">
                            <div class="float-left">
                                <div>
                                    <button class="btn btn-danger mr-2" (click)="deleteAuditData()">
                                        Delete
                                    </button>
                                </div>
                                <div class="mt-2 mb-2">
                                </div>
                            </div>
                            <div class="float-right">
                                <div id="export_csv"
                                    class="btn btn-lg btn-sm btn-primary rounded-pill py-2 px-4 mr-3 mt-2"
                                    style="font-size: 0.875rem;" (click)="exportData()">
                                    Export
                                </div>
                            </div>
                        </div>

                        <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                            <thead>
                                <tr>
                                    <th>
                                        <input type="checkbox" name="id" (click)="selectAll()"
                                            [checked]="isSelectAllChecked">
                                    </th>
                                    <th>Date</th>
                                    <th>Agent ID</th>
                                    <th> No of answered questions</th>
                                    <th> City Name</th>
                                    <th>Store Name</th>
                                    <th>Branch Name</th>
                                    <th>Store type</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let audit of auditData">
                                    <td>
                                        <input type="checkbox" name="id" (change)='rowSelected(audit?.id)'
                                            [checked]="audit.isChecked">
                                    </td>
                                    <td> {{audit.date}}</td>
                                    <td>
                                        <a [routerLink]="['/merch/users']" [queryParams]="{id: audit.agent_user}">{{
                                            audit.agent_user}}</a>
                                    </td>

                                    <td>
                                        <a routerLink="/merch/audit-response/{{ audit.id }}">
                                            {{audit.response_count}}
                                        </a>
                                    </td>
                                    <td> {{ audit.city}}</td>
                                    <td> {{ audit.store}}</td>
                                    <td> {{ audit.branch}}</td>
                                    <td> {{ audit.store_type}}</td>

                                </tr>
                            </tbody>
                        </table>
                        <div class="row ml-2 flex d-flex justify-content-start mt-2">
                            Showing {{ gLimit || 0 }}.
                        </div>

                        <div class="row mr-2 flex d-flex justify-content-end" style="margin-top: -15px;">
                            <button class="btn btn-warning mr-2" (click)="handlePagination('previous')"
                                [disabled]="!previousUrl">
                                Previous</button>
                            <button class="btn btn-primary" (click)="handlePagination('next')" [disabled]="!nextUrl">
                                Next</button>
                        </div>
                    </div>
                </div> <!-- container-fluid -->

            </div> <!-- content -->

            <!-- Footer Start -->
            <app-footer></app-footer>
            <!-- end Footer -->

        </div>

        <!-- ============================================================== -->
        <!-- End Page content -->
        <!-- ============================================================== -->
    </div>
    <!-- END wrapper -->
</body>

</html>