import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { SharedService } from '../_shared/shared.service';

@Injectable({
  providedIn: 'root'
})
export class MerchDashboardService {

  constructor(
    private sharedService: SharedService
  ) { }

  /**
   * 1. Import Price Monitor Snap Data
   */


  // export data API
  exportPMSnapData() {
    return this.sharedService.get(`${environment.baseUrl}/snap/price-monitor/export`);
  }

  /**
   * 2. View PM Snap Data
   * @param  {} filterString?
   */
  viewPMSnapData(limit, offset, dateBefore, dateAfter) {
    return this.sharedService.get(
      `${environment.baseUrl}/snap/price-monitor/list?limit=${limit}&date_before=${dateBefore}&date_after=${dateAfter}`
    );
  }
  viewSnapDataFilter(limit, type, filter) {
    return this.sharedService.get(
      `${environment.baseUrl}/snap/${type}/list?${filter}limit=${limit}`
    );
  }

  // search PM API
  searchPMSnapData(searchText: string) {
    if (!searchText) {
      return this.viewPMSnapData(10, 0, '', '')
    }
    return this.sharedService.get(`${environment.baseUrl}/snap/price-monitor/list?search=${searchText}`);
  }

  /**
   * 3.  Price monitor Snap - All price measures
   * @param  {} filterString?
   */

  csvReport(filterString?) {
    return this.sharedService.post(`${environment.baseUrl}/merch/report/overview`, filterString);

  }
  totalStores(filterString?) {
    return this.sharedService.post(`${environment.baseUrl}/merch/report/total-stores`, filterString);
  }
  availabilityReport(filterString?) {
    return this.sharedService.post(`${environment.baseUrl}/merch/report/availability`, filterString);
  }
  outOfStockReport(filterString?) {
    return this.sharedService.post(`${environment.baseUrl}/merch/report/out-of-stock`, filterString);
  }

  selfShareReport(filterString?) {
    return this.sharedService.post(`${environment.baseUrl}/merch/report/self-share`, filterString);
  }

  selfBalanceReport(filterString?) {
    return this.sharedService.post(`${environment.baseUrl}/merch/report/shelf-balance`, filterString);
  }

  secondaryDisplayReport(filterString?) {
    return this.sharedService.post(`${environment.baseUrl}/merch/report/secondary-display`, filterString);
  }

  storeBalanceReport(filterString?) {
    return this.sharedService.post(`${environment.baseUrl}/merch/report/store-balance`, filterString);
  }

  priceMinMaxReport(filterString?) {
    return this.sharedService.post(`${environment.baseUrl}/merch/report/price-monitor-overview`, filterString);
  }

  promotionReport(filterString?) {
    return this.sharedService.post(`${environment.baseUrl}/merch/report/promotion`, filterString);
  }

  cleanObject(obj: any) {
    const { subcategory_ids, brand_ids, sku_ids, ...rest } = obj;
    return rest;
  }

  auditImageReport(filterString?, currentCursor?: string, limit?: number) {

    let newb: any = {}
    if (filterString) {
      const body = this.cleanObject(filterString);
      console.log(body)
      const branchNoids = filterString.branchno_ids
      delete body.branchno_ids
      newb = { ...body, branch_no_ids: branchNoids }
    }

    console.log("Image filter", newb)
    if (currentCursor) {
      return this.sharedService.post(`${currentCursor}`, newb);
    } else {
      return this.sharedService.post(`${environment.baseUrl}/merch/report/audit-image?limit=${limit}`, newb);
    }
  }

  /**
   * 4. Price Monitor Snap - Month, Maximum Price
   * @param  {} filterString?
   */
  pmMonthMaxPrice(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/snap/price-monitor/report/month-max${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/snap/price-monitor/report/month-max`);
  }

  /**
   * 5. Price Monitor Snap - Month, Maximum Price
   * @param  {} filterString?
   */
  pmMonthMinPrice(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/snap/price-monitor/report/month-min${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/snap/price-monitor/report/month-min`);
  }

  /**
   * 6.  Price Monitor Snap - Total Brand, All Price measures
   * @param  {} filterString?
   */
  pmTotalBrandAllPriceMeasures(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/snap/price-monitor/report/brand-overview${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/snap/price-monitor/report/brand-overview`);
  }


  /**
   * 7. Price Monitor Snap - Minimum Price by country
   * @param  {} filterString?
   */
  pmMinPriceByCountry(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/snap/price-monitor/report/country-min${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/snap/price-monitor/report/country-min`);
  }

  /**
   * 8. Price Monitor Snap - Month, Mode Price
   * @param  {} filterString?
   */
  pmMonthModePrice(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/snap/price-monitor/report/month-mode${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/snap/price-monitor/report/month-mode`);
  }

  /**
   * 9. Price Monitor Snap - Month, Mean Price
   * @param  {} filterString?
   */
  pmMonthMeanPrice(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/snap/price-monitor/report/month-mean${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/snap/price-monitor/report/month-mean`);
  }


  /**
   * 10.  Price Monitor Snap - outlet Visited per city
   * @param  {} filterString?
   */
  pmOutletVisitedPerCity(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/snap/price-monitor/report/visit-per-city${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/snap/price-monitor/report/visit-per-city`);
  }
  /**
   * 11. Price Monitor Snap - Maximum Price by country
   * @param  {} filterString?
   */
  pmMaxPriceByCountry(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/snap/price-monitor/report/country-max${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/snap/price-monitor/report/country-max`);
  }

  /**
   * 12. Price Monitor Snap - Total Country Mode Price
   * @param  {} filterString?
   */
  pmTotalCountryModePrice(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/snap/price-monitor/report/country-mode${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/snap/price-monitor/report/country-mode`);
  }

  /**
   * 13.  Price Monitor Snap - Total Country Mean Price
   * @param  {} filterString?
   */
  pmTotalCountryMeanPrice(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/snap/price-monitor/report/country-mean${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/snap/price-monitor/report/country-mean`);
  }

  /**
   * 14. Price Monitor Snap - Number of outlets Visisted by Country
   * @param  {} filterString?
   */
  pmNumberOfOutletsVisitedByCountry(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/snap/price-monitor/report/visit-per-country${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/snap/price-monitor/report/visit-per-country`);
  }

  /**
   * 15. Price Monitor Snap - SKU count, by channel
   * @param  {} filterString?
   */
  pmSKUCountByChannel(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/snap/price-monitor/report/sku-per-channel${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/snap/price-monitor/report/sku-per-channel`);
  }

  /**
   * 16. Price Monitor Snap - Maximum Price by channel
   * @param  {} filterString?
   */
  pmMinPriceByChannel(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/snap/price-monitor/report/channel-min${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/snap/price-monitor/report/channel-min`);
  }

  /**
   * 17. Price Monitor Snap - Mean Price by channel
   * @param  {} filterString?
   */
  pmMeanPriceByChannel(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/snap/price-monitor/report/channel-mean${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/snap/price-monitor/report/channel-mean`);
  }

  /**
   * 18. Price Monitor Snap - Mode Price by channel
   * @param  {} filterString?
   */
  pmModePriceByChannel(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/snap/price-monitor/report/channel-mode${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/snap/price-monitor/report/channel-mode`);
  }
  /**
   * 19. Price Monitor Snap - Minimum Price by channel
   * @param  {} filterString?
   */
  pmMaxPriceByChannel(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/snap/price-monitor/report/channel-max${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/snap/price-monitor/report/channel-max`);
  }
  /**
   * 20. Price Monitor Snap - Minimum Price by city
   * @param  {} filterString?
   */
  pmMinPriceByCity(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/snap/price-monitor/report/city-min${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/snap/price-monitor/report/city-min`);
  }

  /**
   * 21. Price Monitor Snap - Mean Price by city
   * @param  {} filterString?
   */
  pmMeanPriceByCity(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/snap/price-monitor/report/city-mean${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/snap/price-monitor/report/city-mean`);
  }

  /**
   * 22. Price Monitor Snap - Mode Price by city
   * @param  {} filterString?
   */
  pmModePriceByCity(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/snap/price-monitor/report/city-mode${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/snap/price-monitor/report/city-mode`);
  }
  /**
   * 23. Price Monitor Snap - Maximum Price by city
   * @param  {} filterString?
   */
  pmMaxPriceByCity(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/snap/price-monitor/report/city-max${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/snap/price-monitor/report/city-max`);
  }
  /**
   * 24. Price Monitor Snap - Minimum Price by brand
   * @param  {} filterString?
   */
  pmMinPriceByBrand(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/snap/price-monitor/report/brand-min${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/snap/price-monitor/report/brand-min`);
  }

  /**
   * 25. Price Monitor Snap - Mean Price by brand
   * @param  {} filterString?
   */
  pmMeanPriceByBrand(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/snap/price-monitor/report/brand-mean${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/snap/price-monitor/report/brand-mean`);
  }

  /**
   * 26. Price Monitor Snap - Mode Price by brand
   * @param  {} filterString?
   */
  pmModePriceByBrand(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/snap/price-monitor/report/brand-mode${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/snap/price-monitor/report/brand-mode`);
  }
  /**
   * 27. Price Monitor Snap - Maximum Price by brand
   * @param  {} filterString?
   */
  pmMaxPriceByBrand(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/snap/price-monitor/report/brand-max${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/snap/price-monitor/report/brand-max`);
  }
  /**
   * 28. Price Monitor Snap - Maximum Price by channel
   * @param  {} filterString?
   */
  pmMinPriceByChannelCity(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/snap/price-monitor/report/channel-city-min${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/snap/price-monitor/report/channel-city-min`);
  }

  /**
   * 29. Price Monitor Snap - Mean Price by channel
   * @param  {} filterString?
   */
  pmMeanPriceByChannelCity(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/snap/price-monitor/report/channel-city-mean${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/snap/price-monitor/report/channel-city-mean`);
  }

  /**
   * 30. Price Monitor Snap - Mode Price by channel
   * @param  {} filterString?
   */
  pmModePriceByChannelCity(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/snap/price-monitor/report/channel-city-mode${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/snap/price-monitor/report/channel-city-mode`);
  }
  /**
   * 31. Price Monitor Snap - Minimum Price by channel
   * @param  {} filterString?
   */
  pmMaxPriceByChannelCity(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/snap/price-monitor/report/channel-city-max${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/snap/price-monitor/report/channel-city-max`);
  }

  /**
   * 32. Price Monitor Snap - list months
   * @param  {} filterString?
   */
  pmListMonths(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/snap/price-monitor/month-list${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/snap/price-monitor/month-list`);
  }

  /**
   * 1. Import Price Monitor Snap Data
   */

  importOOSSnapData(csvData) {
    return this.sharedService.post(`${environment.baseUrl}/snap/out-of-stock/import`, csvData);
  }

  /**
   * 2. View Snap OOS Data
   * @param  {} limit
   * @param  {} offset
   */
  viewSnapOOSData(limit, offset, dateBefore, dateAfter) {
    return this.sharedService.get(
      `${environment.baseUrl}/snap/out-of-stock/list?limit=${limit}&date_before=${dateBefore}&date_after=${dateAfter}`
    );
  }

  // search PM API
  searchSnapOOSData(searchText: string) {
    if (!searchText) {
      return this.viewSnapOOSData(10, 0, '', '')
    }
    return this.sharedService.get(`${environment.baseUrl}/snap/out-of-stock/list?search=${searchText}`);
  }

  /**
   * 3. Snap data - OOS All measures
   * @param  {} filterString?
   */
  OOSAllMeasures(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/snap/out-of-stock/report/overview${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/snap/out-of-stock/report/overview`);
  }

  /**
   * 4. Snap data - OOS Available SKU
   * @param  {} filterString?
   */
  OOSAvailableSKU(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/snap/out-of-stock/report/available${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/snap/out-of-stock/report/available`);
  }

  /**
   * 5. Snap data - OOS Not available SKU
   * @param  {} filterString?
   */
  OOSNotAvailableSKU(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/snap/out-of-stock/report/not-available${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/snap/out-of-stock/report/not-available`);
  }

  /**
   * 6. Snap data - OOS Less than 6 packs SKU
   * @param  {} filterString?
   */
  OOSLessThanSixPackSku(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/snap/out-of-stock/report/less${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/snap/out-of-stock/report/less`);
  }

  /**
   * 7. Snap data - OOS AVailable SKU by city
   * @param  {} filterString?
   */
  OOSAvailableSkuByCity(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/snap/out-of-stock/report/available-by-city${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/snap/out-of-stock/report/available-by-city`);
  }

  /**
   * 8. Snap data - OOS Not available SKU by city
   * @param  {} filterString?
   */
  OOSNotAvailableSkuByCity(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/snap/out-of-stock/report/not-available-by-city${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/snap/out-of-stock/report/not-available-by-city`);
  }

  /**
   * 9. Snap data - OOS Total outlet visited by city
   * @param  {} filterString?
   */
  OOSTotalOutletVisitedByCity(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/snap/out-of-stock/report/visit-by-city${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/snap/out-of-stock/report/visit-by-city`);
  }

  /**
   * 10. Snap data - OOS Lessthan 6 packes SKU by city
   * @param  {} filterString?
   */
  OOSLessThanSixPacksSkuByCity(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/snap/out-of-stock/report/less-by-city${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/snap/out-of-stock/report/less-by-city`);
  }

  /**
   * 11. Snap data - Not available SKU for 4 weeks (for a month by week)
   * @param  {} filterString?
   */
  OOSNotAvailableSKUByMonths(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/snap/out-of-stock/report/not-available-by-week${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/snap/out-of-stock/report/not-available-by-week`);
  }
  // OOS by city
  OOSByCity(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/snap/out-of-stock/report/city-report${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/snap/out-of-stock/report/city-report`);
  }
  /// OOS by city
  OOSByChannelCity(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/snap/out-of-stock/report/city-channel-report${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/snap/out-of-stock/report/city-channel-report`);
  }

  // DC by month
  OOSByMonth(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/snap/out-of-stock/report/month-report${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/snap/out-of-stock/report/month-report`);
  }

  // DC by store
  OOSByStore(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/snap/out-of-stock/report/store-report${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/snap/out-of-stock/report/store-report`);
  }

  OOSListMonths(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/snap/out-of-stock/month-list${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/snap/out-of-stock/month-list`);
  }

  /**
   * 1. Import Distribution Check Snap Data
   */

  importDCSnapData(csvData) {
    return this.sharedService.post(`${environment.baseUrl}/snap/distribution/import`, csvData);
  }

  /**
   * 2. View Snap DC Data
   * @param  {} limit
   * @param  {} offset
   */
  viewSnapDCData(limit, offset, dateBefore, dateAfter) {
    return this.sharedService.get(
      `${environment.baseUrl}/snap/distribution/list?list=${limit}&date_before=${dateBefore}&date_after=${dateAfter}`
    );
  }

  // search PM API
  searchSnapDCData(searchText: string) {
    if (!searchText) {
      return this.viewSnapDCData(10, 0, '', '')
    }
    return this.sharedService.get(`${environment.baseUrl}/snap/distribution/list?search=${searchText}`);
  }

  // Distribution Check APIs
  /**
   * @param  {} filterString
   * 1) Total outlet visited by city
   */
  getDCSubmittedQnnByCity(filterString) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/snap/distribution/report/visit-by-city${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/snap/distribution/report/visit-by-city`);
  }

  /**
   * @param  {} filterString
   * 2) total outlets visited by country
   */
  getDCSubmittedQnnByCountry(filterString) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/snap/distribution/report/visit-by-country${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/snap/distribution/report/visit-by-country`);

  }

  /**
   * @param  {} filterString
   * 3) Total Outlet Visited by channel
   */
  getSubmittedQnnByChannel(filterString) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/snap/distribution/report/visit-by-channel${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/snap/distribution/report/visit-by-channel`);
  }

  /**
   * @param  {} filterString
   * 3) Total Distribution
   */
  getTotalDistribution(filterString) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/snap/distribution/report/total-distribution${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/snap/distribution/report/total-distribution`);
  }
  /**
   * @param  {} filterString
   * 3) Total Distribution
   */
  getShelfShareByCity(filterString) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/snap/distribution/report/shelf-share${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/snap/distribution/report/shelf-share`);
  }
  /**
   * @param  {} filterString
   * 3) Total Distribution
   */
  getTotalOutletVisited(filterString) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/snap/distribution/report/number-of-outlet${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/snap/distribution/report/number-of-outlet`);
  }

  /**
   * @param  {} filterString
   * 4) SKU distribution by city
   */
  getTotalOutletsInCity(filterString) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/snap/distribution/report/sku-by-city${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/snap/distribution/report/sku-by-city`);
  }

  /**
   * @param  {} filterString
   * 5) Total SKU by country
   */

  getTotalOutletsInCountry(filterString) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/snap/distribution/report/sku-by-country${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/snap/distribution/report/sku-by-country`);
  }

  /**
   * @param  {} filterString
   * 6) Total SKU by channel
   */

  getTotalOutletsInChannel(filterString) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/snap/distribution/report/sku-by-channel${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/snap/distribution/report/sku-by-channel`);
  }

  /**
   * @param  {} filterString
   * 7) Total Brand by City
   */

  getSumOfOneBrandByCity(filterString) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/report/distribution-check/brand-per-city${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/report/distribution-check/brand-per-city`);
  }

  /**
   * @param  {} filterString
   * 8) Total Brand by Country
   */

  getSumOfOneBrandByCountry(filterString) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/report/distribution-check/brand-per-country${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/report/distribution-check/brand-per-country`);
  }

  /**
   * @param  {} filterString
   * 9) Total Brand by Channel
   */

  getSumOfOneBrandByChannel(filterString) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/report/distribution-check/brand-per-channel${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/report/distribution-check/brand-per-channel`);
  }

  /**
   * @param  {} filterString
   * 10) Total Average Per SKU
   */
  getDCAvgPerSku(filterString) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/snap/distribution/report/share-sku-by-country${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/snap/distribution/report/share-sku-by-country`);
  }

  /**
   * @param  {} filterString
   * 11) Total Average per Brand
   */
  getDCAvgPerBrand(filterString) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/snap/distribution/report/share-brand-by-country${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/snap/distribution/report/share-brand-by-country`);
  }

  /**
   * @param  {} filterString
   * 12) Shelf Share of SKU, by Channel
   */
  getDCShelfShareSkuPerChannel(filterString) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/snap/distribution/report/share-sku-by-channel${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/snap/distribution/report/share-sku-by-channel`);
  }

  /**
   * @param  {} filterString
   * 13) Shelf Share of Brand, by Channel
   */
  getDCShelfShareBrandPerChannel(filterString) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/snap/distribution/report/share-brand-by-channel${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/snap/distribution/report/share-brand-by-channel`);
  }

  // DC by city
  DCByCountry(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/snap/distribution/report/country-report${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/snap/distribution/report/country-report`);
  }
  DCByCity(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/snap/distribution/report/city-report${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/snap/distribution/report/city-report`);
  }

  DCListMonths(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/snap/distribution/month-list${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/snap/distribution/month-list`);
  }

  // DC by channel
  DCByChannel(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/snap/distribution/report/channel-report${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/snap/distribution/report/channel-report`);
  }
  // DC by channel city
  DCByChannelCity(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/snap/distribution/report/channel-city-report${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/snap/distribution/report/channel-city-report`);
  }
  // DC by brand
  DCByBrand(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/snap/distribution/report/brand-report${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/snap/distribution/report/brand-report`);
  }
  // DC by sku
  DCBySku(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/snap/distribution/report/sku-report${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/snap/distribution/report/sku-report`);
  }


  // Consumer Check APIs

  /**
   * 1. Import Consumer data
   */

  importCCSnapData(csvData) {
    return this.sharedService.post(`${environment.baseUrl}/snap/consumer/import`, csvData);
  }

  /**
   * 2. View Consumer Data
   * @param  {} filterString?
   */
  viewSnapCCData(limit, offset, dateBefore, dateAfter) {
    return this.sharedService.get(
      `${environment.baseUrl}/snap/consumer/list?limit${limit}&date_before=${dateBefore}&date_after=${dateAfter}`
    );
  }

  // search PM API
  searchSnapCCData(searchText: string) {
    if (!searchText) {
      return this.viewSnapCCData(10, 0, '', '')
    }
    return this.sharedService.get(`${environment.baseUrl}/snap/consumer/list?search=${searchText}`);
  }

  /**
   * @param  {} filterString
   * 3) Snap Data - Consumer Yes or No
   */
  SnapDataYesOrNo(filterString) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/snap/consumer/report/yes-no-question${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/snap/consumer/report/yes-no-question`);
  }

  /**
   * @param  {} filterString
   * 4) Snap Data - Consumer Rating three points
   */
  SnapDataConsumerRatingThreePoints(filterString) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/snap/consumer/report/rating-one-to-three${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/snap/consumer/report/rating-one-to-three`);

  }

  /**
   * @param  {} filterString
   * 5) Snap Data - Consumer Rating five points
   */
  SnapDataConsumerRatingFivePoints(filterString) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/snap/consumer/report/rating-one-to-five${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/snap/consumer/report/rating-one-to-five`);
  }

  /**
   * @param  {} filterString
   * 6) Snap Data - Consumer Rating ten points
   */
  SnapDataConsumerRatingTenPoints(filterString) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/snap/consumer/report/rating-one-to-ten${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/snap/consumer/report/rating-one-to-ten`);
  }

  /**
   * @param  {} filterString
   * 7) Snap Data - Consumer Average
   */

  SnapDataConsumerAverage(filterString) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/snap/consumer/report/numeric-average${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/snap/consumer/report/numeric-average`);
  }

  /**
   * @param  {} filterString
   * TODO: Needs to be replaced
   * 8) Option Questions
   */
  getCCOptionQuestions(filterString) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/report/consumer-questionnaire/options-question${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/report/consumer-questionnaire/options-question`);
  }

  // shelf by city
  shelfCQByCity(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/snap/price-monitor/report/brand-mean${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/snap/price-monitor/report/brand-mean`);
  }
  // Distribution by city
  distributionCQByCity(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/snap/price-monitor/report/brand-mean${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/snap/price-monitor/report/brand-mean`);
  }
  // shelf by channel
  shelfCQByChannel(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/snap/price-monitor/report/brand-mean${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/snap/price-monitor/report/brand-mean`);
  }
  // Distribution by channel
  distributionCQByChannel(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/snap/price-monitor/report/brand-mean${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/snap/price-monitor/report/brand-mean`);
  }
  // Shelf by brand
  shelfCQByBrand(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/snap/price-monitor/report/brand-mean${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/snap/price-monitor/report/brand-mean`);
  }
  // Distribution by brand
  distributionCQByBrand(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/snap/price-monitor/report/brand-mean${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/snap/price-monitor/report/brand-mean`);
  }
  // shelf by sku
  shelfCQBySku(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/snap/price-monitor/report/brand-mean${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/snap/price-monitor/report/brand-mean`);
  }
  // Distribution by sku
  distributionCQBySku(filterString?) {
    if (filterString)
      return this.sharedService.get(`${environment.baseUrl}/snap/price-monitor/report/brand-mean${filterString}`);
    return this.sharedService.get(`${environment.baseUrl}/snap/price-monitor/report/brand-mean`);
  }

  // bulk delete
  bulkSnapDelete(accessToken: string, deletedIds: { snap_ids: number[] }, questionnaireType: string) {
    return new Promise((resolve, reject) => {

      if (deletedIds.snap_ids.length < 1) {
        reject({
          status: false,
          message: "No records selected!"
        });
      }

      Swal.fire({
        icon: 'warning',
        title: 'Are you sure want to delete selected rows?',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
        showLoaderOnConfirm: true,
        preConfirm: function () {
          $.ajax({
            headers: { 'Authorization': `JWT ${accessToken}`, 'Content-Type': 'application/json' },
            url: `${environment.baseUrl}/snap/${questionnaireType}/bulk-delete`,
            type: 'POST',
            data: JSON.stringify(deletedIds)
          })
            .done(function (response) {
              resolve(true);
            })
            .fail(function () {
              reject({ status: false, messsage: 'Something went wrong while deleting records!' });
            });
        }
      });
    });
  }
}


